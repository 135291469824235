import { MicroApp } from 'umi';
import { useSelector, useDispatch } from 'dva';
import { getJWT } from '@utils/jwt';
import { appNames } from '@constants/platform';
import { formatAmount } from '@utils/tools';
import { reportMessage, reportError } from '@utils/sentry';

export default function MyPage({ app }) {
  const {
    currentLang,
    currentCurrency,
    scope,
    currentPlatform,
    exchangeRate,
    defaultCurrency: defaultCurrencyState,
    logo: logoState,
    menus: menusState,
    currencies: currenciesState,
    languages: languagesState,
    defaultOpenMenus: defaultOpenMenusState,
    homeMenus: homeMenusState,
    token: tokenState,
    email,
  } = useSelector(state => state.global || {});

  const token = tokenState || getJWT(currentPlatform);
  //exchange default currency to current currency
  const exchange = value => {
    if (!value && value !== 0) return value;

    const rate = (exchangeRate[defaultCurrencyState] || {})[currentCurrency];

    return rate ? formatAmount(value * rate) : formatAmount(value);
  };

  //exchange current currency to default currency
  const exchangeBack = value => {
    if (!value && value !== 0) return value;

    const rate = (exchangeRate[currentCurrency] || {})[defaultCurrencyState];

    return rate ? formatAmount(value * rate) : formatAmount(value);
  };

  const dispatch = useDispatch();

  const handleUpdateConfig = ({
    logo,
    menus,
    currencies,
    defaultCurrency,
    languages,
    defaultLang,
    defaultOpenMenus,
    homeMenus,
  }) => {
    dispatch({
      type: 'global/setData',
      payload: {
        logo: logo || logoState,
        menus: menus || menusState || [],
        currencies: currencies || currenciesState || [],
        currentCurrency: defaultCurrency || currentCurrency,
        languages: languages || languagesState || [],
        currentLang: defaultLang || currentLang,
        defaultOpenMenus: defaultOpenMenus || defaultOpenMenusState || [],
        subAppMounted: true,
        homeMenus: homeMenus || homeMenusState || [],
        defaultCurrency: defaultCurrency || defaultCurrencyState,
      },
    });
  };

  const handleUpdateLanguage = ({ languages, defaultLang }) => {
    dispatch({
      type: 'global/setData',
      payload: {
        languages,
        currentLang: defaultLang,
      },
    });
  };

  const handleUpdateCurrency = ({ currencies, defaultCurrency }) => {
    dispatch({
      type: 'global/setData',
      payload: {
        currencies,
        currentCurrency: defaultCurrency,
        defaultCurrency,
      },
    });
  };

  const handleLogin = ({ email, password, redirect }) => {
    dispatch({
      type: 'global/login',
      payload: {
        email,
        password,
        redirect,
      },
    });
  };

  const handleOtp = ({ email, code, redirect }) => {
    dispatch({
      type: 'global/otp',
      payload: {
        email,
        code,
        redirect,
      },
    });
  };

  const handleGoogleOAuth = () => {
    const currentPlatform = window.location.pathname.match(/\/([\w|-]*)/)[1];
    const appName = appNames[currentPlatform];
    window.location.href = `${
      process.env.REACT_APP_LOGIN_URL
    }/login/oauth_authorize?authType=google&redirectTo=${
      window.location.href
    }?appName=${appName || 'newtond'}&platform=${currentPlatform}`;
  };

  return (
    <MicroApp
      name={app}
      style={{ height: '100%' }}
      currentLang={currentLang}
      currentCurrency={currentCurrency}
      scope={scope}
      updateConfig={handleUpdateConfig}
      updateLanguage={handleUpdateLanguage}
      updateCurrency={handleUpdateCurrency}
      token={token}
      exchangeRate={exchangeRate}
      login={handleLogin}
      otpLogin={handleOtp}
      googleLogin={handleGoogleOAuth}
      locale={currentLang}
      exchange={exchange}
      exchangeBack={exchangeBack}
      reportError={reportError}
      reportMessage={reportMessage}
      email={email}
    />
  );
}
