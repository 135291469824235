import SideBar from './SideBar/en-US';
import Header from './Header/en-US';
import Login from './Login/en-US';
import TimeSelect from './TimeSelect/en-US';

export default {
  'global.status': 'status',
  'global.quantity': 'quantity',
  'global.amount': 'amount',
  'global.coverageAmount': 'coverage amount',
  'global.percentage': 'percentage',
  'global.maxClaimAmount': 'max claim amount',
  'global.order': 'Order',
  'global.policy': 'Policy',
  'global.claim': 'Claim',
  'global.reimburse': 'Reimbursement',
  'global.claimAmount': 'claim amount',
  'global.vs': 'vs',
  'global.search': 'Search',
  'global.download': 'Download',
  'global.upload': 'Upload',
  'global.date': 'Date',
  'global.back': 'Back',
  'global.next': 'Next',
  'global.confirmNewPassword': 'Confirm New Password',
  'global.newPassword': 'New Password',
  'global.oldPassword': 'Old Password',
  'global.proxyLogin': 'Proxy Account',
  'global.noAccessTip':
    'You do not have the access to Admin Portal. Please contact the administrator to apply for a permission.',
  ...SideBar,
  ...Header,
  ...Login,
  ...TimeSelect,
};
