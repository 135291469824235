export default {
  'login.enterYourLoginDetails':
    'Masukkan detail login Anda di bawah ini untuk mengakses',
  'login.the8DigitCode':
    'Kode 8 digit akan dikirim melalui email untuk memverifikasi izin Anda.',
  'login.welcome': 'Selamat datang',
  'login.email': 'Surel',
  'login.password': 'Kata sandi',
  'login.passcode': 'Kode sandi',
  'login.or': 'ATAU',
  'login.log.in': 'Gabung',
  'login.account': 'Akun',
  'login.send': 'Kirim',
  'login.resend': 'Kirim ulang',
  'login.in': 'Dalam',
};
