import { useDispatch, useSelector } from 'dva';
import { IntlProvider } from 'react-intl';
import { Layout } from 'antd';
import SideBar from './SideBar';
import HeadBar from './Header';
import styles from './index.less';
import id from '@locales/id-ID';
import en from '@locales/en-US';
import zh from '@locales/zh-CN';
import vi from '@locales/vi-VN';
import { removeJWT, getJWT } from '@utils/jwt';
import { useEffect } from 'react';
import _ from 'lodash';
import { history } from 'umi';

const locales = {
  'id-ID': id,
  'en-US': en,
  'zh-CN': zh,
  'vi-VN': vi,
};

const { Sider, Content, Header } = Layout;

function BasicLayout(props) {
  const { currentLang, subAppMounted, currentPlatform, token } = useSelector(
    state => ({
      ...(state.global || {}),
    }),
  );
  const dispatch = useDispatch();
  const logout = () => {
    dispatch({
      type: 'global/setData',
      payload: {
        token: undefined,
      },
    });
    removeJWT(currentPlatform);
    history.push('/' + currentPlatform + '/login');
  };

  useEffect(() => {
    if (token) {
      localStorage.setItem('v1LastMouseMoveTime', Date.now());
      const throttled = _.throttle(
        () => {
          const now = Date.now();
          if (getJWT(currentPlatform)) {
            localStorage.setItem('v1LastMouseMoveTime', now);
          } else {
            dispatch({
              type: 'global/setData',
              payload: {
                token: undefined,
              },
            });
            removeJWT(currentPlatform);
            history.push('/' + currentPlatform + '/login');
          }
        },
        5000,
        { trailing: true },
      );
      document.body.addEventListener('mousemove', throttled);

      const timer = setInterval(() => {
        if (
          Number(Date.now()) -
            Number(localStorage.getItem('v1LastMouseMoveTime')) >
          10 * 60 * 1000
        ) {
          logout();
        }
      }, 60000);

      return () => {
        clearInterval(timer);
        document.body.removeEventListener('mousemove', throttled);
        localStorage.removeItem('v1LastMouseMoveTime');
      };
    }
  }, [token]);

  const { location, children } = props;

  return (
    <IntlProvider
      locale={currentLang || 'en-US'}
      messages={locales[currentLang] || en}
    >
      <Layout className={styles.layout}>
        {subAppMounted &&
          !location.pathname.includes('/login') &&
          !location.pathname.includes('/403') && (
            <Sider className={styles.sider} width={180}>
              <SideBar
                currentMenu={location.pathname}
                currentPlatform={currentPlatform}
              />
            </Sider>
          )}
        <Layout style={{ overflow: 'hidden' }}>
          {subAppMounted &&
            !location.pathname.includes('/login') &&
            !location.pathname.includes('/403') && (
              <Header className={styles.header}>
                <HeadBar />
              </Header>
            )}
          <Content className={styles.content}>{children}</Content>
        </Layout>
      </Layout>
    </IntlProvider>
  );
}

export default BasicLayout;
