import { Button } from 'antd';
import forbidden from '@assets/403.svg';
import { history } from 'umi';
import { removeJWT } from '@utils/jwt';
import { useIntl } from 'react-intl';
import { useDispatch } from 'dva';

export default ({
  match: {
    params: { platform },
  },
}) => {
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch({
      type: 'global/setData',
      payload: {
        token: '',
      },
    });
    removeJWT(platform);
    history.push(`/${platform}/login`);
  };

  const { formatMessage } = useIntl();

  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <div style={{ margin: '240px auto', textAlign: 'center' }}>
        <img src={forbidden} style={{ width: 84, height: 100 }} />
        <div
          style={{
            fontSize: 30,
            color: '#212121',
            fontWeight: 500,
            marginTop: 48,
          }}
        >
          403 Forbidden
        </div>
        <div style={{ fontSize: 20, color: '#424242', marginTop: 16 }}>
          {formatMessage({ id: 'global.noAccessTip' })}
        </div>
        <Button
          type="default"
          style={{ width: 200, marginTop: 24 }}
          onClick={handleLogout}
        >
          {formatMessage({ id: 'operation.logout' })}
        </Button>
      </div>
    </div>
  );
};
