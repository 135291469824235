import SideBar from './SideBar/id-ID';
import Header from './Header/id-ID';
import Login from './Login/id-ID';
import TimeSelect from './TimeSelect/id-ID';

export default {
  'global.status': 'status',
  'global.quantity': 'jumlah',
  'global.amount': 'nilai',
  'global.coverageAmount': 'nilai perlindungan',
  'global.maxClaimAmount': 'nilai maksimum klaim',
  'global.percentage': 'persentase',
  'global.order': 'Pemesanan',
  'global.policy': 'Polis',
  'global.claim': 'Klaim',
  'global.reimburse': 'Reimbursement',
  'global.claimAmount': 'jumlah klaim',
  'global.vs': 'dibandingkan',
  'global.search': 'Cari',
  'global.download': 'Unduh',
  'global.upload': 'Unggah',
  'global.date': 'Tanggal',
  'global.back': 'Kembali',
  'global.next': 'Selanjutnya',
  'global.confirmNewPassword': 'Konfirmasi Password Baru',
  'global.newPassword': 'Password Baru',
  'global.oldPassword': 'Password Lama',
  'global.noAccessTip':
    'Anda tidak mempunyai keizinan untuk mengakses portal pentadbir. Sila hubungi pentadbir aplikasi keizinan.',
  ...SideBar,
  ...Header,
  ...Login,
  ...TimeSelect,
};
