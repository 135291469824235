export default {
  'login.enterYourLoginDetails':
    'Quý khách vui lòng điền thông tin để đăng nhập',
  'login.the8DigitCode':
    'Mã số sẽ được gửi đến email của Quý khách để xác nhận.',
  'login.welcome': 'Chào mừng Quý khách',
  'login.email': 'Email',
  'login.password': 'Mật khẩu',
  'login.passcode': 'Mã số',
  'login.or': 'HOẶC',
  'login.log.in': 'Đăng nhập',
  'login.account': 'Tài khoản',
  'login.send': 'Gửi',
  'login.resend': 'Gửi lại',
  'login.in': 'vào',
};
