import SideBar from './SideBar/vi-VN';
import Header from './Header/vi-VN';
import Login from './Login/vi-VN';
import TimeSelect from './TimeSelect/vi-VN';

export default {
  'global.status': 'trạng thái',
  'global.quantity': 'số lượng',
  'global.amount': 'số tiền',
  'global.coverageAmount': 'số tiền bảo hiểm',
  'global.percentage': 'phần trăm',
  'global.maxClaimAmount': 'số tiền yêu cầu bồi thường tối đa',
  'global.order': 'Sắp xếp',
  'global.policy': 'Hợp đồng bảo hiểm',
  'global.claim': 'Đơn bồi thường',
  'global.reimburse': 'Bồi thường',
  'global.claimAmount': 'số tiền bồi thường',
  'global.vs': 'với',
  'global.search': 'Tìm kiếm',
  'global.download': 'Tải xuống',
  'global.upload': 'Tải lên',
  'global.date': 'Ngày',
  'global.back': 'Quay lại',
  'global.next': 'Kế tiếp',
  'global.confirmNewPassword': 'Xác nhận lại mật khẩu mới',
  'global.newPassword': 'Mật khẩu mới',
  'global.oldPassword': 'Mật khẩu cũ',
  'global.noAccessTip':
    'Bạn không có quyền truy cập. Vui lòng liên hệ hoặc xin cấp quyền truy cập.',
  ...SideBar,
  ...Header,
  ...Login,
  ...TimeSelect,
};
