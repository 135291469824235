export default {
  'sidebar.overview': '概况',
  'sidebar.order': '订单',
  'sidebar.invoice': '收据',
  'sidebar.refund': '退款',
  'sidebar.bankCard': '银行卡',
  'sidebar.paymentWay': '支付方式',
  'sidebar.upload': '上传',
  'sidebar.insurer': '保险公司',
  'sidebar.shop-overview': '店家-概况',
  'sidebar.shop-invoice': '店家-收据',
  'sidebar.shop-refund': '店家-退款',
  'sidebar.shop-report': '店家-报告',
  'sidebar.shop-manage': '店家-管理',
  'sidebar.shop': '店铺',
  'sidebar.dashboard': 'Dashboard',
};
