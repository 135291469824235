module.exports = [
  'bukalapak',
  'lazada',
  'shopee-my',
  'shippit',
  'reddoorz',
  'shopee-xb',
  'bhinneka',
  'lex',
  'jdid',
  'foodpanda',
  'akulaku',
  'ubp',
  'mitra',
  'msig',
  'telkomsel',
  'bhinneka',
  'philinsure',
  'aeon',
  'mobicare',
];
