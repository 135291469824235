import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: process.env.SENTRY_URL,
  tracesSampleRate: 0,
  environment: process.env.UMI_ENV,
});

export const reportMessage = message => {
  Sentry.captureMessage(message);
};

export const reportError = message => {
  Sentry.captureException(new Error(message));
};

export const updateInformation = ({ email, currentPlatform }) => {
  email && Sentry.configureScope(scope => scope.setUser({ email }));
  currentPlatform &&
    Sentry.configureScope(scope => scope.setTag('platform', currentPlatform));
};
