export function setJWT(platform, user_token) {
  localStorage.setItem(
    `${process.env.REACT_APP_ACCESS_TOKEN_NAME}_${platform}`,
    user_token,
  );
}

export function getJWT(platform) {
  return localStorage.getItem(
    `${process.env.REACT_APP_ACCESS_TOKEN_NAME}_${platform}`,
  );
}

export function removeJWT(platform) {
  localStorage.removeItem(`${process.env.REACT_APP_LOGIN_NAME}_${platform}`);
  localStorage.removeItem(
    `${process.env.REACT_APP_ACCESS_TOKEN_NAME}_${platform}`,
  );
  if (platform === 'shopee-my') {
    localStorage.removeItem('shopeemy-job');
  }
}

export function parseJWT(platform) {
  const token = getJWT(platform);

  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  } catch (error) {
    return null;
  }
}
