import TimeSelect from './TimeSelect/zh-CN';
import Header from './Header/zh-CN';
import SideBar from './SideBar/zh-CN';
import Login from './Login/zh_CN';

export default {
  'global.status': '状态',
  'global.quantity': '数量',
  'global.amount': '金额',
  'global.coverageAmount': '承保金额',
  'global.percentage': '百分比',
  'global.maxClaimAmount': '最高索赔额',
  'global.policy': '保单',
  'global.claim': '理赔',
  'global.reimburse': 'Reimbursement',
  'global.claimAmount': '理赔额',
  'global.vs': 'vs',
  'global.download': '下载',
  'global.upload': '上传',
  'global.date': '日期',
  'global.back': '返回',
  'global.next': '下一步',
  'global.search': '搜索',
  'operation.logout': '登出',
  'global.confirmNewPassword': '确认新密码',
  'global.newPassword': '新密码',
  'global.oldPassword': '原密码',
  'global.proxyLogin': '代理登录',
  'global.noAccessTip': '您没有登陆Admin Panel的权限，请联系系统管理员。',
  ...TimeSelect,
  ...SideBar,
  ...Login,
  ...Header,
};
