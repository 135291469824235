import { history } from 'umi';
import {
  login,
  otp,
  getExchangeRate,
  exchangeToken,
  getNewPlatformScope,
  changePwd,
} from '../services/service';
import { setJWT, parseJWT, getJWT } from '@utils/jwt';
import moment from 'moment';
import get from 'lodash.get';
import { reportMessage, updateInformation } from '@utils/sentry';

export default {
  namespace: 'global',
  state: {
    currentPlatform: sessionStorage.getItem('currentPlatform') || null,
    languages: [],
    currentLang: null,
    currencies: [],
    currentCurrency: null,
    exchangeRate: {},
    subAppMounted: false,
    token: getJWT(sessionStorage.getItem('currentPlatform')) || undefined,
  },
  reducers: {
    setData(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: {
    *login(action, { call, put, select }) {
      const platform = window.location.pathname.match(/\/([\w|-]*)/)[1];
      const { email, password, redirect } = action.payload;
      let { user_token, http_request_failed } = yield call(
        login,
        email,
        password,
        platform,
      );

      yield put({
        type: 'setData',
        payload: {
          email,
        },
      });

      if (!http_request_failed) {
        yield put({
          type: 'exchangeToken',
          payload: {
            platform,
            token: user_token,
          },
        });

        if (typeof redirect === 'function') {
          redirect();
        } else {
          history.push(`${platform}${redirect.replace(platform, '')}`);
        }
      }
    },
    *otp(action, { call, put, select }) {
      const platform = window.location.pathname.match(/\/([\w|-]*)/)[1];
      const { email, code, redirect } = action.payload;
      let { jwtToken, http_request_failed } = yield call(
        otp,
        email,
        code,
        platform,
      );

      if (!http_request_failed && jwtToken) {
        yield put({
          type: 'exchangeToken',
          payload: {
            platform,
            token: jwtToken,
          },
        });

        if (typeof redirect === 'function') {
          redirect();
        } else {
          history.push(`${platform}${redirect.replace(platform, '')}`);
        }
      }
    },
    *exchangeToken({ payload }, { call, put }) {
      const { platform } = payload;

      let token = payload.token;

      if (
        ![
          'reddoorz',
          'akulaku',
          'bukalapak',
          'jdid',
          'foodpanda',
          'ubp',
          'mitra',
          'aeon',
          'philinsure',
          'msig',
          'telkomsel',
          'mobicare',
        ].includes(platform)
      ) {
        const { access_token } = yield call(exchangeToken, token, platform);

        token = access_token;
      }

      setJWT(platform, token);
      yield put({
        type: 'setData',
        payload: {
          token,
        },
      });

      yield put({
        type: 'init',
      });
    },
    *init(action, { call, put, select }) {
      const currentPlatform = window.location.pathname.match(/\/([\w|-]*)/)[1];
      sessionStorage.setItem('currentPlatform', currentPlatform);
      updateInformation({ currentPlatform });
      const isLogin = window.location.pathname.includes('login');
      const msgs = parseJWT(currentPlatform);
      let newPlatformScope = null;

      if (!isLogin || msgs) {
        if (!msgs) {
          switch (currentPlatform) {
            case 'bukalapak':
            case 'ubp':
            case 'reddoorz':
            case 'akulaku':
            case 'jdid':
            case 'foodpanda':
            case 'aeon':
            case 'philinsure':
            case 'msig':
            case 'shippit':
            case 'shopee-xb':
            case 'telkomsel':
            case 'bhinneka':
            case 'mobicare':
              return history.push(`/${currentPlatform}/login`);
            default:
              return (window.location.href = currentPlatform
                ? `${window.location.origin}/${currentPlatform}/login`
                : `${window.location.origin}/login`);
          }
        }

        if (currentPlatform === 'bukalapak' || currentPlatform === 'aeon') {
          newPlatformScope = {};
          const rst = yield call(
            getNewPlatformScope,
            getJWT(currentPlatform),
            currentPlatform,
          );
          const permissions = get(rst, 'permissions.permissions', []);
          permissions.forEach(key => {
            newPlatformScope[key] = true;
          });
        }

        const {
          role,
          scope,
          app_data: { email },
        } = msgs;

        const _scope = newPlatformScope || scope || {};

        if (
          currentPlatform === 'bukalapak' &&
          (!_scope || !Object.keys(_scope).length)
        ) {
          return history.push(`/${currentPlatform}/403`);
        }

        const { rates, http_request_failed } = yield call(
          getExchangeRate,
          moment().format('YYYY-MM-DD'),
        );

        if (http_request_failed) {
          yield put({
            type: 'setData',
            payload: {
              getExchangeFailed: true,
            },
          });

          return;
        }

        const exchangeRate = {};

        if (!http_request_failed) {
          Object.keys(rates).forEach(base => {
            const baseValue = rates[base];
            exchangeRate[base] = {};

            Object.keys(rates).forEach(target => {
              const targetValue = rates[target];
              exchangeRate[base][target] = baseValue
                ? targetValue / baseValue
                : 1;
            });
          });
        }

        yield put({
          type: 'setData',
          payload: {
            role,
            scope: _scope,
            email,
            getExchangeFailed: !Object.keys(exchangeRate),
            exchangeRate,
            currentPlatform,
          },
        });
        updateInformation({ email });
        reportMessage(`${email} logined at ${new Date().getTime()}`);
      } else {
        yield put({
          type: 'setData',
          payload: {
            currentPlatform,
          },
        });
      }
    },
    *changePwd({ payload }, { call, select }) {
      const { currentPlatform } = yield select(state => state.global || {});
      const { callback, ...rest } = payload;

      const { http_request_failed } = yield call(
        changePwd,
        currentPlatform,
        rest,
      );

      if (!http_request_failed && typeof callback === 'function') {
        callback();
      }
    },
    *langChange({ payload }, { put, select }) {
      yield put({
        type: 'setData',
        payload,
      });
    },
  },
  subscriptions: {
    setup({ dispatch, history }) {
      const { token } = history.location.query;
      const platform = history.location.pathname.match(/\/([\w|-]*)/)[1];
      if (token) {
        dispatch({
          type: 'exchangeToken',
          payload: {
            platform,
            token,
          },
        });
      }

      dispatch({
        type: 'init',
      });
    },
  },
};
