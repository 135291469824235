export default {
  'login.enterYourLoginDetails': 'Enter your login details below to access',
  'login.the8DigitCode':
    'The 8-digit code will be send via email to verify your permission.',
  'login.welcome': 'Welcome',
  'login.email': 'Email',
  'login.password': 'Password',
  'login.passcode': 'Passcode',
  'login.or': 'OR',
  'login.log.in': 'Log In',
  'login.account': 'Account',
  'login.send': 'Send',
  'login.resend': 'Resend',
  'login.in': 'In',
};
