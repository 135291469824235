export default {
  'sidebar.overview': 'Overview',
  'sidebar.order': 'Order',
  'sidebar.insurer': 'Insurer',
  'sidebar.invoice': 'Invoice',
  'sidebar.policy': 'Policy',
  'sidebar.claim': 'Claim',
  'sidebar.reimburse': 'Reimburse',
  'sidebar.reconciliation': 'Reconciliation',
  'sidebar.refund': 'Refund',
  'sidebar.upload': 'Upload',
  'sidebar.user': 'User',
  'sidebar.bankCard': 'Bank Card',
  'sidebar.paymentWay': 'Payment Way',
  'sidebar.upload': 'Upload',
  'sidebar.user': 'User',
  'sidebar.bill': 'Bill',
  'sidebar.shop-overview': 'Shop-Overview',
  'sidebar.shop-invoice': 'Shop-Invoice',
  'sidebar.shop-refund': 'Shop-Refund',
  'sidebar.shop-report': 'Shop-Report',
  'sidebar.shop-manage': 'Shop-Manage',
  'sidebar.shop': 'Shop',
  'sidebar.dashboard': 'Dashboard',
  'sidebar.device': 'Device',
  'sidebar.repairshop': 'Repair Shop',
  'sidebar.activitylog': 'Activity Log',
};
