export default {
  'login.enterYourLoginDetails': '请输入登录信息',
  'login.the8DigitCode': '验证码即将发送至您的邮箱',
  'login.welcome': '欢迎',
  'login.email': '邮箱',
  'login.password': '密码',
  'login.passcode': '验证码',
  'login.or': '或者',
  'login.log.in': '登陆',
  'login.account': '用户名',
  'login.send': '发送',
  'login.resend': '重新发送',
  'login.in': '使用',
};
