import React, { useState } from 'react';
import { Select, Popover, Modal, Input, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'dva';
import { useIntl } from 'react-intl';
import { removeJWT } from '@utils/jwt';
import styles from './Header.less';

const { Password } = Input;

const Option = Select.Option;

export default function Header(props) {
  const { formatMessage } = useIntl();
  const [showChangePwd, setShowChangePwd] = useState(false);
  const {
    email,
    languages = [],
    currentLang,
    currencies = [],
    currentCurrency,
    currentPlatform,
    getExchangeFailed,
    logo,
    homeMenus = [],
  } = useSelector(state => state.global || {});
  const dispatch = useDispatch();

  const changeLang = currentLang => {
    sessionStorage.setItem('currentLang', currentLang);

    dispatch({
      type: 'global/langChange',
      payload: {
        currentLang,
      },
    });
  };

  const changeCurrency = currentCurrency => {
    dispatch({
      type: 'global/setData',
      payload: {
        currentCurrency,
      },
    });
  };

  const handleChangePwd = data => {
    dispatch({
      type: 'global/changePwd',
      payload: {
        ...data,
        email,
        callback: () => setShowChangePwd(false),
      },
    });
  };

  return (
    <div className={styles.header}>
      <div className={styles.logoBox}>
        <img src={logo} alt="" className={styles.logo} />
      </div>
      <div className={styles.rightBox}>
        <Select
          value={currentLang}
          className={styles.selectLang}
          onSelect={changeLang}
          disabled={!currentLang}
        >
          {languages.map(({ title, key }) => (
            <Option key={key} value={key}>
              {title}
            </Option>
          ))}
        </Select>
        <Select
          value={currentCurrency}
          className={styles.selectCurrency}
          onSelect={changeCurrency}
          disabled={!currentCurrency || getExchangeFailed}
        >
          {currencies.map(({ title, key }) => (
            <Option key={key} value={key}>
              {title}
            </Option>
          ))}
        </Select>
        <div className={styles.avatar}>
          <svg
            className={styles.avatarIcon}
            focusable="false"
            viewBox="0 0 24 24"
            aria-hidden="true"
            role="presentation"
          >
            <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path>
          </svg>
        </div>
        <span className={styles.email}>{email}</span>
        <Popover
          content={
            <div className={styles.popContent}>
              {homeMenus.map(({ label, onClick }) => (
                <div className={styles.opItem} onClick={onClick}>
                  {formatMessage({ id: label })}
                </div>
              ))}
              <div
                className={styles.opItem}
                onClick={() => setShowChangePwd(true)}
              >
                {formatMessage({ id: 'operation.resetPassword' })}
              </div>
              <div
                className={styles.opItem}
                onClick={() => {
                  removeJWT(currentPlatform);
                  window.location = `${window.location.origin}/${currentPlatform}/login`;
                }}
              >
                {formatMessage({ id: 'operation.logout' })}
              </div>
            </div>
          }
          getPopupContainer={trigger => trigger.parentNode}
          trigger="click"
          placement="bottom"
        >
          <div className={styles.operator}>
            <DownOutlined />
          </div>
        </Popover>
        <ChangePassword
          visible={showChangePwd}
          onClose={() => setShowChangePwd(false)}
          onOk={handleChangePwd}
        />
      </div>
    </div>
  );
}

function ChangePassword({ visible, onClose, onOk }) {
  const [oldPwd, setOldPwd] = useState();
  const [newPwd, setNewPwd] = useState();
  const [confirmPwd, setConfirmPwd] = useState();
  const [error, setError] = useState(false);
  const { formatMessage } = useIntl();

  const handleConfirm = () => {
    if (newPwd !== confirmPwd) {
      setError(true);

      return;
    }

    onOk({
      old_password: oldPwd,
      new_password: newPwd,
    });
  };

  return (
    <Modal
      visible={visible}
      footer={null}
      width={600}
      destroyOnClose
      onCancel={onClose}
    >
      <div className={styles.modal}>
        <div className={styles.title}>
          {formatMessage({ id: 'operation.resetPassword' })}
        </div>
        <div className={styles.inputBlock}>
          <div className={styles.label}>
            {formatMessage({ id: 'global.oldPassword' })}:
          </div>
          <div className={styles.value}>
            <Password
              className={styles.input}
              value={oldPwd}
              onChange={e => {
                setOldPwd(e.target.value);
                if (error) setError(false);
              }}
            />
          </div>
        </div>
        <div className={styles.inputBlock}>
          <div className={styles.label}>
            {formatMessage({ id: 'global.newPassword' })}:
          </div>
          <div className={styles.value}>
            <Password
              className={styles.input}
              value={newPwd}
              onChange={e => {
                setNewPwd(e.target.value);
                if (error) setError(false);
              }}
            />
          </div>
        </div>
        <div className={styles.inputBlock}>
          <div className={styles.label}>
            {formatMessage({ id: 'global.confirmNewPassword' })}:
          </div>
          <div className={styles.value}>
            <Password
              className={styles.input}
              value={confirmPwd}
              onChange={e => {
                setConfirmPwd(e.target.value);
                if (error) setError(false);
              }}
            />
          </div>
        </div>
        {error && (
          <div style={{ marginTop: 16, color: '#f52209' }}>
            Password not match.
          </div>
        )}
        <div className={styles.buttonGroup}>
          <Button onClick={onClose} className={styles.button}>
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={handleConfirm}
            className={styles.button}
            disabled={!oldPwd || !newPwd || !confirmPwd}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
}
