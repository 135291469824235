import platforms from '../config/platform';
import devEntries from '../config/devEntries';
import * as Sentry from '@sentry/react';

export const dva = {
  config: {
    onError(err) {
      err.preventDefault();
      Sentry.captureException(err);
    },
  },
};

export const qiankun = {
  apps:
    process.env.NODE_ENV === 'development'
      ? devEntries
      : platforms.map(platform => ({
          name: platform,
          entry: `${window.location.origin}/static/${platform}/index.html`,
        })),
};
