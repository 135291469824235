import umi_request from 'umi-request';
import { removeJWT } from '../utils/jwt';
import { appNames } from '@constants/platform';
import { reportError } from '@utils/sentry';
import { notification } from 'antd';

/**
 * 异常处理程序
 */
const errorHandler = async error => {
  let rst = null;
  const { response } = error;
  if (response && response.status) {
    const { status, url } = response;
    console.log(status, url);

    try {
      rst = await response.json();
    } catch {
      rst = null;
      console.log('non-json response');
    }

    if (rst) {
      notification.error({
        message: rst.message || rst.msg || 'Request Failed!',
      });
      reportError(JSON.stringify({ url, message: rst.message }));
    }

    if (status === 401) {
      removeJWT('aeon');
      history.push('/login');
    }
  } else if (!response) {
    console.log('error');
  }

  return {
    http_request_failed: true,
  };
};

export const login = (email, password, platform) => {
  return umi_request.post(`${process.env.REACT_APP_API_URL}/login`, {
    data: {
      email,
      password,
      appName: appNames[platform] || 'newtond',
    },
    credentials: 'include',
    errorHandler,
  });
};

export const getNewPlatformScope = (token, platform) => {
  return umi_request(`${process.env.REACT_APP_API_URL}/permissions`, {
    params: {
      token,
    },
    credentials: 'include',
    errorHandler: error => {
      const { response } = error;
      if (response && response.status) {
        const { status } = response;

        if (status === 401) {
          removeJWT(platform);
          history.push('/' + platform + '/login');
        }
      } else if (!response) {
        console.log('error');
      }

      return null;
    },
  });
};

export const exchangeToken = (ax_token, platform) => {
  return umi_request.post(
    `${process.env.REACT_APP_API_URL}/${platform}/transit/login/token`,
    {
      data: {
        ax_token,
        service: 'transit',
      },
      credentials: 'include',
      errorHandler,
    },
  );
};

export const otp = (email, code, platform) => {
  return umi_request.post(`${process.env.REACT_APP_API_URL}/login/otp`, {
    data: {
      email,
      code,
      appName: appNames[platform] || 'newtond',
    },
    credentials: 'include',
    errorHandler,
  });
};

export const getExchangeRate = date => {
  return umi_request.get(`${process.env.REACT_APP_CURRENCY_API}?date=${date}`, {
    errorHandler,
  });
};

export const changePwd = (platform, data) => {
  return umi_request.post(`${process.env.REACT_APP_API_URL}/reset-password`, {
    params: {
      ...data,
      appName: appNames[platform] || 'newtond',
    },
    credentials: 'include',
    errorHandler,
  });
};
