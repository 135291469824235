import { useMemo } from 'react';
import { Menu } from 'antd';
import getMenu from './menu';
import { useSelector } from 'dva';
import { history } from 'umi';
import { useIntl } from 'react-intl';
import icon from '@assets/icon.png';
import Loading from '@components/Loading';
import styles from './SideBar.less';

const { Item, SubMenu } = Menu;

const getSelectedKey = (menus, currentMenu) => {
  let selectedKey = null;

  menus.forEach(({ children }) => {
    if (selectedKey || !children) return;

    children.forEach(({ key }) => {
      if (currentMenu.includes(key)) {
        selectedKey = key;

        return;
      }
    });
  });

  return selectedKey;
};

export default function SideBar(props) {
  const { formatMessage } = useIntl();
  const clickMenu = ({ item }) => {
    const { path, inline } = item.props['data-item'];

    if (!inline) {
      return (window.location.href = path);
    }

    history.push(path);
  };

  const { currentMenu, currentPlatform } = props;
  const { scope = {}, menus, defaultOpenMenus, loading } = useSelector(
    state => ({
      ...state.global,
      loading: state.loading.effects['global/init'],
    }),
  );

  const authedMenus = useMemo(() => getMenu(menus, scope), [menus, scope]);

  const selectedKey = getSelectedKey(authedMenus, currentMenu);

  if (
    menus &&
    menus.length &&
    (!authedMenus || !authedMenus.length) &&
    window.location.pathname.includes('/403') &&
    !loading
  ) {
    history.push(`/${currentPlatform}/403`);
  }

  return (
    <div className={styles.sideBar}>
      <div className={styles.title}>
        <img src={icon} alt="" className={styles.logo} />
        <span className={styles.productName}>
          {formatMessage({ id: 'sidebar.dashboard' })}
        </span>
      </div>
      {authedMenus && authedMenus.length && (
        <Menu
          mode="inline"
          className={styles.menu}
          // inlineIndent={26}
          selectedKeys={[selectedKey]}
          onClick={clickMenu}
          defaultOpenKeys={defaultOpenMenus}
        >
          {authedMenus.map(({ title, children }) => (
            <SubMenu key={title} title={title} className={styles.groupTitle}>
              {children &&
                children.map(item => (
                  <Item
                    key={item.key}
                    className={styles.menuItem}
                    data-item={item}
                  >
                    <div className={styles.iconBox}>
                      <img
                        src={
                          currentMenu.includes(item.key)
                            ? item.iconS
                            : item.icon
                        }
                        alt=""
                        className={styles.icon}
                      />
                    </div>
                    <span className={styles.text}>
                      {formatMessage({ id: item.title })}
                    </span>
                  </Item>
                ))}
            </SubMenu>
          ))}
        </Menu>
      )}
    </div>
  );
}
