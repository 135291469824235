export default {
  'sidebar.overview': 'Ikhtisar',
  'sidebar.order': 'Pesanan',
  'sidebar.insurer': 'Pemberi Asuransi',
  'sidebar.invoice': 'Faktur',
  'sidebar.policy': 'Polis',
  'sidebar.claim': 'Klaim',
  'sidebar.reimburse': 'Reimburse',
  'sidebar.reconciliation': 'Reconciliation',
  'sidebar.user': 'User',
  'sidebar.dashboard': 'Dashboard',
  'sidebar.repairshop': 'Repair Shop',
  'sidebar.activitylog': 'Activity Log',
};
