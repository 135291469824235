import platforms from '@config/platform';

export const allPlatforms = platforms;

export const api_url = {
  bukalapak: process.env.REACT_APP_API, //这里需要考虑一下
  bukalapak_login: process.env.REACT_APP_BUKALAPAK_API_URL,
  bukalapak_transit: process.env.REACT_APP_BUKALAPAK_TRANSIT_API_URL,
  bukalapak_mitra: process.env.REACT_APP_MITRA_API_URL,
  lazada: process.env.REACT_APP_LAZADA_API_URL,
  'shopee-my': process.env.REACT_APP_SHOPEE_MY_API_URL,
  'shopee-my_login': process.env.REACT_APP_SHOPEE_MY_API_URL,
  shippit: process.env.REACT_APP_SHIPPIT_API_URL,
  shippit_login: process.env.REACT_APP_SHIPPIT_API_URL,
  reddoorz: process.env.REACT_APP_REDDOORZ_API_URL,
  'shopee-xb': process.env.REACT_APP_SHOPEE_XB_API_URL,
  bhinneka: process.env.REACT_APP_BHINNEKA_API_URL,
  bhinneka_login: process.env.REACT_APP_BHINNEKA_API_URL,
  lex: process.env.REACT_APP_LEX_API_URL,
  akulaku: process.env.REACT_APP_AKULAKU_API_URL,
  jdid: process.env.REACT_APP_JDID_API_URL,
  ubp: process.env.REACT_APP_UBP_API_URL,
  foodpanda: process.env.REACT_APP_FOODPANDA_API_URL,
  aeon: process.env.REACT_APP_AEON_API_URL,
  philinsure: process.env.REACT_APP_PHILINSURE_API_URL,
  telkomsel: process.env.REACT_APP_TELKOMSEL_API_URL,
};

export const api_key = {
  bukalapak: process.env.REACT_APP_BUKALAPAK_API_KEY,
  lazada: process.env.REACT_APP_LAZADA_API_KEY,
  'shopee-my': process.env.REACT_APP_SHOPEE_MY_API_KEY,
  shippit: process.env.REACT_APP_SHIPPIT_API_KEY,
  reddoorz: process.env.REACT_APP_REDDOORZ_API_KEY,
  'shopee-xb': process.env.REACT_APP_SHOPEE_XB_API_KEY,
  bhinneka: process.env.REACT_APP_BHINNEKA_API_KEY,
  lex: process.env.REACT_APP_LEX_API_KEY,
  akulaku: process.env.REACT_APP_AKULAKU_API_KEY,
  jdid: process.env.REACT_APP_JDID_API_KEY,
  ubp: process.env.REACT_APP_UBP_API_KEY,
  foodpanda: process.env.REACT_APP_FOODPANDA_API_KEY,
  mitra: process.env.REACT_APP_MITRA_API_KEY,
  aeon: process.env.REACT_APP_AEON_API_KEY,
  philinsure: process.env.REACT_APP_PHILINSURE_API_KEY,
  telkomsel: process.env.REACT_APP_TELKOMSEL_API_KEY,
};

export const appNames = {
  reddoorz: 'reddoorz',
  bukalapak: 'bukalapak',
  ubp: 'gip',
  aeon: 'aeon_gadget',
};
